<template>
  <TabNavigation :is-active="taskStatus.bulkPrice.status" />
  <LoadingScreen :isLoading="isLoading" />
  <div class="p-0 h-full w-full z-[9999999] overflow-y-auto overflow-x-hidden" v-show="!hideUI">
    <Card class="p-0 bg-gray-100">
      <template #content>
        <Card class="p-0">
          <template #title>
            <h6 class="text-base mb-2">Bulk Adjust Price</h6>
          </template>
          <template #content>
            <div v-if="validations.has('priceSettings')">
              <Message
                severity="error"
                v-for="(errorMessage, index) of validations.get('priceSettings')"
                :keys="errorMessage"
                icon="null"
                :closable="false"
              >
                {{ errorMessage }}
              </Message>
            </div>
            <div class="py-3">
              <h6 class="text-base">Time Delay</h6>
              <div class="flex justify-between w-full">
                <div class="flex items-center mb-2">
                  <InputNumber
                    v-model="delayRange[0]"
                    :min="0"
                    :max="delayRange[1] - 1"
                    inputClass="!w-[30px] text-center"
                    :disabled="taskStatus.bulkPrice.status"
                  />
                  <span>Sec</span>
                </div>
                <div class="flex items-center mb-2">
                  <InputNumber
                    v-model="delayRange[1]"
                    :min="delayRange[0] + 1"
                    :max="60"
                    inputClass="!w-[30px] text-center"
                    :disabled="taskStatus.bulkPrice.status"
                  />
                  <span>Sec</span>
                </div>
              </div>
              <Slider
                v-on:update:model-value="updateSliderValue"
                :pt="sliderStyles"
                v-model="delayRange"
                range
                :step="1"
                ariaLabel="sec"
                :min="1"
                :max="60"
                class="mx-2"
                :disabled="taskStatus.bulkPrice.status"
              />
            </div>
            <div class="grid grid-cols-3 gap-x-4 items-center mt-4">
              <label for="" class="col-span-1"> Change price of listings </label>
              <SelectButton
                :pt="priceLabelSettings"
                v-model="changeMode"
                :options="increaseOptions"
                option-label="label"
                option-value="value"
                aria-labelledby="basic"
                class="mb-2 col-span-2"
                :disabled="taskStatus.bulkPrice.status"
              />
            </div>
            <div class="flex items-center gap-x-3 pt-4">
              <label for="percentChange" class="block mb-2"> Percent to adjust prices </label>
              <InputNumber
                :min="10"
                placeholder="0"
                :input-class="'w-[60px] p-3 border border-[#0070b7] rounded-lg '"
                v-model="percentChange"
                inputId="percentChange"
                :useGrouping="true"
                :disabled="taskStatus.bulkPrice.status"
              />
              <span>%</span>
            </div>
          </template>
        </Card>
        <Card class="mt-4 p-0">
          <template #title>
            <h6 class="text-base">Select base price for adjustment</h6>
          </template>
          <template #content>
            <div v-if="validations.has('priceAdjustment')">
              <Message
                severity="error"
                v-for="(errorMessage, index) of validations.get('priceAdjustment')"
                :keys="errorMessage"
                icon="null"
                :closable="false"
              >
                {{ errorMessage }}
              </Message>
            </div>
            <div class="flex flex-col gap-2 mt-2">
              <div class="flex items-center">
                <RadioButton
                  v-model="priceReference"
                  inputId="currentPrice"
                  name="priceReference"
                  :value="BulkPriceReference.CurrentPrice"
                  :disabled="taskStatus.bulkPrice.status"
                />
                <label for="currentPrice" class="ml-2 hover:cursor-pointer"
                  >Current listing price</label
                >
              </div>
              <div class="flex items-center">
                <RadioButton
                  v-model="priceReference"
                  inputId="lowestHistoricalPrice"
                  name="priceReference"
                  :value="BulkPriceReference.LowestPrice"
                  :disabled="taskStatus.bulkPrice.status"
                />
                <label for="lowestHistoricalPrice" class="ml-2 hover:cursor-pointer">
                  Lowest historical price
                </label>
              </div>
            </div>
            <div class="flex items-center gap-x-3 mt-4">
              <label for="minNoOfLikers" class="block">Minimum number of likers</label>
              <InputNumber
                :min="0"
                placeholder="0"
                :input-class="'w-[60px] p-3 border border-[#0070b7] rounded-lg '"
                v-model="minNoOfLikers"
                inputId="minNoOfLikers"
                :useGrouping="true"
                :disabled="taskStatus.bulkPrice.status"
              />
            </div>
          </template>
        </Card>

        <Accordion class="mt-4" v-model:activeIndex="isAdditionalOptionsEnabled">
          <AccordionTab header="Additional Options">
            <div v-if="validations.has('additionalOptions')">
              <Message
                severity="error"
                v-for="(errorMessage, index) of validations.get('additionalOptions')"
                :keys="errorMessage"
                icon="null"
                :closable="false"
              >
                {{ errorMessage }}
              </Message>
            </div>
            <div class="card flex justify-center">
              <div class="card flex flex-col flex-wrap gap-y-3">
                <div class="card grid grid-cols-6 items-center">
                  <InputSwitch
                    input-id="inventoryList"
                    v-model="excludeStatus.inventoryList"
                    onLabel="ON"
                    offLabel="OFF"
                    class="col-span-1"
                    :disabled="taskStatus.bulkPrice.status"
                  />
                  <label :for="'inventoryList'" class="hover:cursor-pointer col-span-5">
                    Exclude items using listing urls
                  </label>
                </div>
                <div v-if="excludeStatus.inventoryList" class="card grid grid-cols-12 items-center">
                  <Chips
                    :pt="chipsListStyles"
                    v-model="exemptInventoryList"
                    separator=","
                    input-class="w-full p-3 "
                    class="col-span-12 grid grid-cols-12 p-2 border border-[#0070b7] rounded-lg"
                    placeholder="Copy product URL and paste it here"
                    :disabled="taskStatus.bulkPrice.status"
                  />
                </div>
                <div class="card grid grid-cols-6 items-center">
                  <InputSwitch
                    input-id="newListings"
                    v-model="excludeStatus.newListings"
                    onLabel="ON"
                    offLabel="OFF"
                    class="w-2rem me-2 col-span-1"
                    :disabled="taskStatus.bulkPrice.status"
                  />
                  <label :for="'newListings'" class="hover:cursor-pointer col-span-5">
                    Exclude new with tags items
                  </label>
                </div>
                <div class="card grid grid-cols-6 items-center">
                  <InputSwitch
                    input-id="boutiqueListings"
                    v-model="excludeStatus.boutiqueListings"
                    onLabel="ON"
                    offLabel="OFF"
                    class="w-2rem me-2 col-span-1"
                    :disabled="taskStatus.bulkPrice.status"
                  />
                  <label :for="'boutiqueListings'" class="hover:cursor-pointer col-span-5">
                    Exclude boutique items
                  </label>
                </div>
                <div class="card grid grid-cols-6 items-center">
                  <InputSwitch
                    input-id="itemsListedLately"
                    v-model="excludeStatus.itemsListedLately"
                    onLabel="ON"
                    offLabel="OFF"
                    class="w-2rem me-2 col-span-1"
                    :disabled="taskStatus.bulkPrice.status"
                  />
                  <label :for="'itemsListedLately'" class="hover:cursor-pointer col-span-5">
                    Exclude items listed lately
                  </label>
                </div>
                <div v-if="excludeStatus.itemsListedLately" class="w-full flex items-center gap-4">
                  <label for="listedLately" class="">Items Listed in the last </label>
                  <InputNumber
                    :min="1"
                    placeholder="0"
                    :input-class="'w-[60px] p-3 border border-[#0070b7] rounded-lg '"
                    v-model="listedLately"
                    inputId="listedLately"
                    :useGrouping="true"
                    :disabled="taskStatus.bulkPrice.status"
                  />
                  <span>days</span>
                </div>
                <div class="card grid grid-cols-6 items-center">
                  <InputSwitch
                    input-id="onlyOldItems"
                    v-model="excludeStatus.onlyOldItems"
                    onLabel="ON"
                    offLabel="OFF"
                    class="w-2rem me-2 col-span-1"
                    :disabled="taskStatus.bulkPrice.status"
                  />
                  <label :for="'onlyOldItems'" class="hover:cursor-pointer col-span-5">
                    Only send offers for old items
                  </label>
                </div>
                <div v-if="excludeStatus.onlyOldItems" class="w-full flex items-center gap-4">
                  <label for="listingsOlderThan" class="">Items Listed </label>
                  <InputNumber
                    :min="1"
                    placeholder="0"
                    :input-class="'w-[60px] p-3 border border-[#0070b7] rounded-lg '"
                    v-model="listingsOlderThan"
                    inputId="listingsOlderThan"
                    :useGrouping="true"
                    :disabled="taskStatus.bulkPrice.status"
                  />
                  <span>days ago</span>
                </div>
                <div class="card grid grid-cols-6 items-center">
                  <InputSwitch
                    input-id="priceRangeForListings"
                    v-model="excludeStatus.priceRangeForListings"
                    onLabel="ON"
                    offLabel="OFF"
                    class="w-2rem me-2 col-span-1"
                    :disabled="taskStatus.bulkPrice.status"
                  />
                  <label :for="'priceRangeForListings'" class="hover:cursor-pointer col-span-5">
                    Only send offers on listings within a price range
                  </label>
                </div>
                <div v-if="excludeStatus.priceRangeForListings" class="w-full">
                  <div class="flex justify-between w-full">
                    <InputNumber
                      v-model="priceRange[0]"
                      :min="minPriceRange"
                      :max="maxPriceRange"
                      placeholder="0"
                      :input-class="'w-[80px] p-3 border border-[#0070b7] rounded-lg text-center'"
                      :disabled="taskStatus.bulkPrice.status"
                      :useGrouping="true"
                    />

                    <InputNumber
                      v-model="priceRange[1]"
                      :min="minPriceRange"
                      :max="maxPriceRange"
                      placeholder="1000"
                      :input-class="'w-[80px] p-3 border border-[#0070b7] rounded-lg mb-4 text-center'"
                      :disabled="taskStatus.bulkPrice.status"
                    />
                  </div>
                  <Slider
                    v-on:update:model-value="updateSliderValue"
                    :pt="sliderStyles"
                    v-model="priceRange"
                    range
                    :step="1"
                    :min="minPriceRange"
                    :max="maxPriceRange"
                    class="mb-4 mx-4"
                    :disabled="taskStatus.bulkPrice.status"
                  />
                </div>
              </div>
            </div>
          </AccordionTab>
        </Accordion>
        <div class="flex justify-end p-1">
          <div class="mt-5">
            <Button
              v-if="taskStatus.bulkPrice.status"
              @click="requireConfirmation('top')"
              class="border border-red-500 font-bold px-8 py-1 bg-red-500 text-white rounded hover:cursor-pointer hover:bg-white hover:text-red-500"
            >
              Stop Current Task
            </Button>
            <Button
              v-else
              class="border border-[#0070b7] font-bold bg-[#0070b7] text-white p-4 rounded hover:cursor-pointer hover:bg-white hover:text-[#0070b7]"
              @click="validateRequest"
            >
              Start
              <div
                v-if="!authStore?.hasActivePlan()"
                class="bg-white rounded-full w-5 h-5 flex items-center justify-center ml-2"
              >
                <i class="pi pi-dollar text-[#0070b7] font-normal text-sm"></i>
              </div>
            </Button>
          </div>
        </div>
      </template>
    </Card>
    <ConfirmDialog group="positioned"></ConfirmDialog>
  </div>
</template>

<script setup lang="ts">
import { onBeforeMount, ref } from 'vue'
import { useTaskStatusStore } from '@/stores/taskStatus'
import { useConfirm } from 'primevue/useconfirm'
import { log, isValidUrl, scrollToTop } from '@/utils/index'
import { useActiveTab } from '@/stores/activeTab'
import { useAuthStore } from '../stores/auth'
import BulkPriceReference from '@/enums/BulkPriceReference'
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import InputText from 'primevue/inputtext'
import Button from 'primevue/button'
import InputNumber from 'primevue/inputnumber'
import InputSwitch from 'primevue/inputswitch'
import Card from 'primevue/card'
import Slider from 'primevue/slider'
import TaskType from '../enums/TaskType'
import SelectButton from 'primevue/selectbutton'
import RadioButton from 'primevue/radiobutton'
import Chips from 'primevue/chips'
import ConfirmDialog from 'primevue/confirmdialog'
import TabNavigation from './TabNavigation.vue'
import Message from 'primevue/message'
import * as Sentry from '@sentry/vue'
import LocalStorageWrapper from '@/classes/LocalStorageWrapper'
import LoadingScreen from '@/components/LoadingScreen.vue'
import { SUBSCRIPTION_TAB_ID } from '@/globals/variables'
import { addStat } from '@/services/stats'
import StatTask from '@/enums/StatsTask'

const hideUI = ref(true)
const isLoading = ref(false)
const localStorageWrapper = LocalStorageWrapper.getInstance()
const authStore = useAuthStore()
const tabView = useActiveTab()
const confirm = useConfirm()
const taskStatus = useTaskStatusStore()
const delayRange = ref<number[]>([30, 40])
const priceReference = ref<BulkPriceReference>(BulkPriceReference.CurrentPrice)
const percentChange = ref<number>(0)
const minNoOfLikers = ref<number>(0)
const exemptInventoryList = ref<string[]>([])
const listedLately = ref<number>(0)
const listingsOlderThan = ref<number>(0)
const minPriceRange = ref<number>(1)
const maxPriceRange = ref<number>(10000)
const priceRange = ref<number[]>([minPriceRange.value, maxPriceRange.value])
const changeMode = ref<boolean>(false)
const scanOlderThan = ref<number>()
const validations = ref(new Map())
const excludeStatus = ref({
  inventoryList: false,
  newListings: false,
  boutiqueListings: false,
  itemsListedLately: false,
  onlyOldItems: false,
  priceRangeForListings: false
})
const isAdditionalOptionsEnabled = ref<number | null>(0)
const increaseOptions = [
  {
    label: 'Increase',
    value: true
  },
  {
    label: 'Decrease',
    value: false
  }
]

//PrimeVue Components Styles
const priceLabelSettings = {
  button: (params: any) => ({
    class: params.context.active ? 'bg-[#0070b7] border-[#0070b7]' : 'bg-gray-100 '
  })
}

const chipsListStyles = {
  token: { class: 'col-span-12 grid grid-cols-12 mx-2  whitespace-pre-wrap break-all' },
  inputtoken: { class: 'col-span-12 grid grid-cols-12  mx-2' },
  input: { class: 'col-span-12' },
  container: { class: 'col-span-12 grid grid-cols-12 justify-center p-1' },
  label: { class: 'col-span-11 whitespace-pre-wrap' },
  removeTokenIcon: { class: 'col-span-1' }
}

const sliderStyles = {
  startHandler: { class: 'bg-[#0070b7]' },
  endHandler: { class: 'bg-[#0070b7]' },
  range: { class: 'bg-[#0070b7]' }
}

const updateSliderValue = (val: number[]) => {
  val[0] = Math.floor(val[0])
  val[1] = Math.floor(val[1])
}

onBeforeMount(async () => {
  //load last used settings if found
  await loadSettings()

  //collapse/expand Additional options accordion
  isAdditionalOptionsEnabled.value =
    excludeStatus.value.inventoryList ||
    excludeStatus.value.newListings ||
    excludeStatus.value.boutiqueListings ||
    excludeStatus.value.itemsListedLately ||
    excludeStatus.value.onlyOldItems ||
    excludeStatus.value.priceRangeForListings
      ? 0
      : null

  hideUI.value = false

  setTimeout(() => {
    isLoading.value = false
  }, 300)
})

const loadSettings = async () => {
  try {
    const storedData = await localStorageWrapper.get('bulkPriceSettings')
    const bulkPriceSettings = JSON.parse(storedData as string)

    if (!bulkPriceSettings) return

    changeMode.value = bulkPriceSettings.changeMode
    percentChange.value = bulkPriceSettings.percentChange
    excludeStatus.value = bulkPriceSettings.excludeStatus
    exemptInventoryList.value = bulkPriceSettings.exemptInventoryList
    listedLately.value = bulkPriceSettings.listedLately
    listingsOlderThan.value = bulkPriceSettings.listingsOlderThan
    priceRange.value = bulkPriceSettings.priceRange ?? [minPriceRange.value, maxPriceRange.value]
    priceReference.value = bulkPriceSettings.priceReference
    minNoOfLikers.value = bulkPriceSettings.minNoOfLikers
    delayRange.value = bulkPriceSettings.delayRange ?? [30, 40]
  } catch (err) {
    //failed to acccess localStorage
    Sentry.captureException(err)
  }
}

const sendMessage = (msg: {
  message: string
  type?: string
  delay?: { min: number; max: number }
  reverse?: string
  percentChange?: number
  excludeStatus?: any
  exemptInventoryList?: string[]
  listedLately?: number
  listingsOlderThan?: number
  priceRange?: number[]
  changeMode?: boolean
  taskType?: TaskType
  time?: string
  repeat?: boolean
}) => {
  window.parent.postMessage(msg, '*')
}

const validateRequest = () => {
  if (!authStore?.hasActivePlan()) {
    upgradeSubscriptionAlert('top')
    return
  }

  if (taskStatus.bulkPrice.status) return

  log('Validating Bulk price request')

  let errorFound = false
  let messages = []

  if (!delayRange.value[0] || delayRange.value[0] < 0)
    messages.push('Delay should be at least 1 second.')
  else if (!delayRange.value[1] || delayRange.value[1] < delayRange.value[0])
    messages.push('Invalid delay range.')

  if (changeMode.value == null) {
    messages.push('Please select a price change mode.')
  }

  if (percentChange.value == null || percentChange.value < 10) {
    messages.push('Adjusted percentage cant be lower than 10%')
  }

  if (percentChange.value > 90) {
    messages.push('Adjusted percentage cant be higher than 90%')
  }

  validations.value.set('priceSettings', messages)

  if (messages.length) errorFound = true

  messages = []

  if (minNoOfLikers.value < 0) {
    messages.push('Minimum number of likes must be greater than 0.')
  }

  validations.value.set('priceAdjustment', messages)

  if (messages.length) errorFound = true

  messages = []

  if (excludeStatus.value.itemsListedLately && listedLately.value <= 0) {
    messages.push('Excluded items days must be greater than 0 days.')
  }

  if (excludeStatus.value.onlyOldItems && listingsOlderThan.value <= 0) {
    messages.push('Items listed days must be greater than 0 days.')
  }

  if (excludeStatus.value.priceRangeForListings) {
    //price range checks
    if (!priceRange.value[0] || priceRange.value[0] < minPriceRange.value)
      messages.push(`Price range can't be less than ${minPriceRange.value}`)
    else if (!priceRange.value[1] || priceRange.value[1] < priceRange.value[0])
      messages.push('Invalid price range')
    else if (!priceRange.value[1] || priceRange.value[1] > maxPriceRange.value)
      messages.push(`Price range can't be more than ${maxPriceRange.value}`)
  }

  if (excludeStatus.value.inventoryList && !exemptInventoryList.value.length) {
    messages.push('Excluded products list is empty.')
  } else if (excludeStatus.value.inventoryList) {
    const invalidUrls = exemptInventoryList.value.filter((item) => !isValidUrl(item))
    if (invalidUrls.length) {
      messages.push(`Invalid urls: ${invalidUrls.join(', ')}`)
    }
  }

  validations.value.set('additionalOptions', messages)

  if (messages.length) errorFound = true

  if (errorFound) {
    scrollToTop()
    return
  }

  //Trim and remove duplicate urls from exemptInventoryList
  exemptInventoryList.value = [...new Set(exemptInventoryList.value.map((item) => item.trim()))]

  //update and send to extension to start task
  const taskData = {
    message: 'bulkPrice',
    percentChange: percentChange.value,
    excludeStatus: JSON.parse(JSON.stringify(excludeStatus.value)),
    exemptInventoryList: JSON.parse(JSON.stringify(exemptInventoryList.value)),
    listedLately: listedLately.value,
    listingsOlderThan: listingsOlderThan.value,
    priceRange: JSON.parse(JSON.stringify(priceRange.value)),
    changeMode: changeMode.value,
    priceReference: priceReference.value,
    minNoOfLikers: minNoOfLikers.value,
    delay: { min: delayRange.value[0], max: delayRange.value[1] }
  }

  //start message listener
  window.addEventListener('message', bulkPriceMessagesHandler)

  sendMessage(taskData)

  taskStatus.changeBulkPriceStatus(true)

  //storing current task configuration to load later to the user
  const config = {
    ...taskData,
    delayRange: delayRange.value
  }

  //store latest settings in local storage
  localStorageWrapper.set('bulkPriceSettings', JSON.stringify(config))
}

const requireConfirmation = (position: any) => {
  confirm.require({
    group: 'positioned',
    icon: 'pi pi-info-circle',
    position: position,
    rejectClass:
      'border border-green-500 font-bold px-8 py-1 bg-green-500 text-white rounded hover:cursor-pointer hover:bg-white hover:text-green-500',
    acceptClass:
      'border border-red-500 font-bold px-8 py-1 bg-red-500 text-white rounded hover:cursor-pointer hover:bg-white hover:text-red-500 outline-0 shadow-none',
    rejectLabel: 'Keep Task running',
    acceptLabel: 'Stop Task',
    header: 'Are you sure you want to stop this task?',
    message: 'Please confirm to proceed.',
    accept: () => {
      sendMessage({ message: 'stopBulkPriceTask' })
    }
  })
}

const upgradeSubscriptionAlert = (position: any) => {
  confirm.require({
    group: 'positioned',
    icon: 'pi pi-info-circle',
    position: position,
    acceptClass:
      'border border-green-500 font-bold px-8 py-1 bg-green-500 text-white rounded hover:cursor-pointer hover:bg-white hover:text-green-500',
    rejectClass:
      'border border-red-500 font-bold px-8 py-1 bg-red-500 text-white rounded hover:cursor-pointer hover:bg-white hover:text-red-500 outline-0 shadow-none',
    rejectLabel: 'Cancel',
    acceptLabel: 'Subscribe',
    header: 'Upgrade Subscription',
    message: 'Upgrade subscription to enable this feature.',
    accept: () => {
      tabView.changeActiveTab(SUBSCRIPTION_TAB_ID)
    }
  })
}

//This will handle messages sent from the extension to the web server
const bulkPriceMessagesHandler = async (event: any) => {
  if (event.data.message == 'bulkPriceTaskStats') {
    // Add a new stat
    console.log('adding custom stat = ', event.data.totalItems)
    if (event.data.totalItems)
      await addStat({ task_name: StatTask.BulkPrice, total: event.data.totalItems })
  } else if (event.data.message == 'bulkPriceTaskEnded') {
    //the stop request was send by the user or if the follow is completed and it is not scheduled to run daily
    if (event.data.requestedByUser || !event.data.isDaily) {
      taskStatus.changeBulkPriceStatus(false)
      //stop message listener until the task is started again
      window.removeEventListener('message', bulkPriceMessagesHandler)
      return
    }
  } else if (event.data.message == 'failedToStartBulkPriceTask') {
    //validations.value.set("priceSettings", [event.data.error]);
    taskStatus.changeBulkPriceStatus(false)
    //stop message listener until the task is started again
    window.removeEventListener('message', bulkPriceMessagesHandler)
  }
}
</script>

<style lang="scss" scoped>
:deep(.p-radiobutton) {
  .p-radiobutton-box {
    border: 1px solid #0070b7;
    border-radius: 50%;
  }
}
</style>
