import { fetchWrapper } from '@/api/fetchWrapper'
import { serverUrl } from '@/globals/env'
import { useAuthStore } from '@/stores/auth'

type Stat = {
  task_name: string
  total: number
  icon: string
  task_duration: number
}

type AddStatPayload = {
  task_name: string
  total: number
}

/**
 * Fetches stats for the last 24 hours for the authenticated user.
 */
export async function fetchStats(): Promise<Stat[] | null> {
  try {
    const authStore = useAuthStore()

    if (!authStore.user) throw new Error('User not logged in.')

    const response = await fetchWrapper('stats', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authStore.user?.token}` // Replace with your token retrieval logic
      }
    })

    // const response = await fetch(serverUrl + 'stats', {
    //   method: 'GET',
    //   headers: {
    //     'Content-Type': 'application/json',
    //     Authorization: `Bearer ${authStore.user?.token}` // Replace with your token retrieval logic
    //   }
    // })

    if (!response.ok) {
      throw new Error(`Error fetching stats: ${response.statusText}`)
    }

    return await response.json()
  } catch (error) {
    console.error('Failed to fetch stats:', error)
    return null
  }
}

/**
 * Adds a new stat entry.
 * @param payload - The task name and total value.
 */
export async function addStat(payload: AddStatPayload): Promise<void> {
  try {
    const authStore = useAuthStore()
    const response = await fetch(serverUrl + 'stats', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authStore.user.token}` // Replace with your token retrieval logic
      },
      body: JSON.stringify(payload)
    })

    if (!response.ok) {
      throw new Error(`Error adding stat: ${response.statusText}`)
    }

    console.log('Stat added successfully')
  } catch (error) {
    console.error('Failed to add stat:', error)
  }
}

/**
 * Mock function to get the user's token.
 */
function getAuthToken(): string {
  return 'your-auth-token' // Replace with actual token retrieval logic.
}
