import { createRouter, createWebHistory } from 'vue-router'
import ShareTab from '@/components/ShareTab.vue'
import FollowTab from '@/components/FollowTab.vue'
import UnfollowTab from '@/components/UnfollowTab.vue'
import HomeTab from '@/components/HomeTab.vue'
import BulkPrice from '@/components/BulkPrice.vue'
import OffersTab from '@/components/OffersTab.vue'
import ClosetCalculatorTab from '@/components/ClosetCalculatorTab.vue'
import ClosetScannerTab from '@/components/ClosetScannerTab.vue'
import CommunityEngagementTab from '@/components/CommunityEngagementTab.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'HomeTab',
      component: HomeTab
    },
    {
      path: '/share',
      name: 'share',
      component: ShareTab
    },
    {
      path: '/follow',
      name: 'follow',
      component: FollowTab
    },
    {
      path: '/unfollow',
      name: 'unfollow',
      component: UnfollowTab
    },
    {
      path: '/bulkPrice',
      name: 'bulkPrice',
      component: BulkPrice
    },
    {
      path: '/offers',
      name: 'offers',
      component: OffersTab
    },
    {
      path: '/closet-calculator',
      name: 'closet-calculator',
      component: ClosetCalculatorTab
    },
    {
      path: '/closet-scanner',
      name: 'closet-scanner',
      component: ClosetScannerTab
    },
    {
      path: '/community-engagement',
      name: 'community-engagement',
      component: CommunityEngagementTab
    }
  ]
})

export default router
