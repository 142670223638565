enum StatTask {
  Share = 'Shares',
  Follow = 'Follows',
  Unfollow = 'Unfollows',
  Offer = 'Offers',
  Captcha = 'Captchas',
  CommunityShare = 'Community Shares',
  BulkPrice = 'Bulk Prices'
}

export const statIcon: { [key in StatTask]: string } = {
  [StatTask.Share]: 'pi-share-alt',
  [StatTask.Follow]: 'pi-user-plus',
  [StatTask.Unfollow]: 'pi-user-minus',
  [StatTask.Offer]: 'pi-dollar',
  [StatTask.Captcha]: 'pi-exclamation-triangle',
  [StatTask.CommunityShare]: 'pi-comments',
  [StatTask.BulkPrice]: 'pi-tags'
}

export default StatTask
