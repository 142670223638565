import ServerRequestError from '@/exceptions/ServerRequestError'
import { serverUrl } from '@/globals/env'
import { sendMessage, log } from '@/utils'
import * as Sentry from '@sentry/vue'
import { addStat } from './stats'
import StatTask from '@/enums/StatsTask'

export const captchaMessagesListener = async (event: any) => {
  if (!event.data && !event.data.message) return

  if (event.data.message == 'solve-captcha-request') {
    solveCaptchaRequest(event.data)
  } else if (event.data.message == 'solve-captcha-follow-up-request') {
    solveCaptchaFollowUp(event.data)
  }
}

const solveCaptchaRequest = async (request: any) => {
  let solveCaptchaResponse
  const { gSiteKey, token, pageURL } = request

  try {
    if (!token) throw new Error(`Invalid call to solveCaptchaRequest. User token is required.`)

    if (!gSiteKey || !pageURL)
      throw new Error(
        `Invalid call to solveCaptchaRequest. Missing parameters ${JSON.stringify(request.message)}`
      )

    const captchaRequest = await fetch(
      serverUrl +
        'solveCaptcha?' +
        'method=userrecaptcha&googlekey=' +
        gSiteKey +
        '&pageurl=' +
        pageURL +
        '&json=1',
      {
        method: 'get',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        }
      }
    )

    //session/token timedout call sync session to update token
    if (captchaRequest.status == 419)
      throw new ServerRequestError('Token has expired - attempting to refresh session.', 419)

    if (captchaRequest.status == 401)
      throw new ServerRequestError(
        'Unauthorized captcha request.',
        ServerRequestError.TOKEN_EXPIRED
      )

    solveCaptchaResponse = await captchaRequest.json()

    if (!solveCaptchaResponse) throw new Error('Server returned empty response.')

    solveCaptchaResponse = JSON.parse(solveCaptchaResponse)

    if (solveCaptchaResponse?.status == 1) {
      request.requestId = solveCaptchaResponse.request
      sendMessage({
        message: 'solve-recaptcha-token-started',
        content: request
      })
    } else {
      //2captcha solve request error
      Sentry.captureException(
        new Error('2captcha solve request error.' + JSON.stringify(solveCaptchaResponse))
      )
      throw new Error('2captcha solve request error.')
    }
  } catch (error) {
    Sentry.captureException(error)
    if (error instanceof ServerRequestError) {
      log(`solveCaptchaRequest error (${error.message}) - status: ${error.status}`)
      //Token expired
      if (error.status === 419) {
        sendMessage({
          message: 'solve-captcha-request-error',
          error: error.message,
          status: 419
        })

        return
      }

      sendMessage({
        message: 'solve-captcha-request-error',
        error: error.message,
        status: error.status
      })
    } else if (error instanceof Error) {
      // Handle general errors
      sendMessage({
        message: 'solve-captcha-request-error',
        error: error.message
      })

      log(`solveCaptchaRequest error (${error.message})`)
    } else {
      // Handle general errors
      sendMessage({
        message: 'solve-captcha-request-error',
        error: error
      })

      log(`solveCaptchaRequest error (${error})`)
    }
  }
}

const solveCaptchaFollowUp = async (event: any) => {
  const { requestId, token } = event.content

  try {
    if (!token) throw new Error(`Invalid call to solveCaptchaFollowUp. User token is required.`)

    if (!requestId)
      throw new Error(
        `Invalid call to solveCaptchaFollowUp. Missing parameters ${JSON.stringify(event.data)}`
      )

    const captchaFollowUpRequest = await fetch(
      serverUrl + 'solveCaptchaFollowUp?' + 'action=get&json=1&id=' + requestId,
      {
        method: 'get',
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'application/json'
        }
      }
    )

    if (!captchaFollowUpRequest.ok) {
      throw new Error(captchaFollowUpRequest.statusText)
    }

    let captchaFollowUpResponse = await captchaFollowUpRequest.json()

    captchaFollowUpResponse = JSON.parse(captchaFollowUpResponse)

    if (!captchaFollowUpResponse || !captchaFollowUpResponse.request)
      throw new Error('Empty captcha response returned.')

    if (captchaFollowUpResponse.request === 'ERROR_CAPTCHA_UNSOLVABLE')
      throw new Error('ERROR_CAPTCHA_UNSOLVABLE')

    if (captchaFollowUpResponse.request === 'CAPCHA_NOT_READY') {
      sendMessage({
        failed: false,
        error: 'Captcha not ready yet',
        message: 'solve-captcha-follow-up-response',
        content: event.content
      })
      //sendResponse({ failed: false, message: "Captcha not ready yet" })
    } else if (captchaFollowUpResponse.status == 1) {
      sendMessage({
        success: true,
        message: 'solve-captcha-follow-up-response',
        successMessage: 'Captcha solution returned',
        gCaptchaToken: captchaFollowUpResponse.request
      })

      //update captchas solved stats
      await addStat({ task_name: StatTask.Captcha, total: 1 })
    } else {
      Sentry.captureException(
        new Error('2captcha general error.' + JSON.stringify(captchaFollowUpResponse))
      )
      //any other unhandled case is usually an unexpected error
      throw new Error(
        'We encountered an error while solving captcha. PoshPop will attempt again shortly.'
      )
    }
  } catch (error) {
    sendMessage({ failed: true, error, message: 'solve-captcha-follow-up-response' })
    //sendResponse({ failed: true, error, message: error })
    log(`solveCaptchaFollowUp error:  ${error}`)
  }
}
