<template>
  <GuideTour />
  <TabView ref="tabComponentRef" />
</template>

<script setup lang="ts">
import GuideTour from '@/components/GuideTour.vue'
import TabView from '@/components/TabView.vue'
import { sendMessage, log } from '@/utils/index'
import { onMounted, onUnmounted, ref } from 'vue'
import { serverUrl } from '@/globals/env.js'
import { syncSession } from '@/services/auth'
import { useAuthStore } from '../stores/auth'
import * as Sentry from '@sentry/vue'
import { latestExtensionVersion } from '@/globals/env'

const authStore = useAuthStore()
const tabComponentRef = ref()

onMounted(() => {
  //check current user extension version, site they are on, etc. and notify if outdated or action is nrequired
  sendMessage({ message: 'get-session-details' })

  //reset listeners to prevent duplicate listeners
  window.addEventListener('message', messagesHandler)
})

onUnmounted(() => {
  //reset listeners to prevent duplicate listeners
  window.removeEventListener('message', messagesHandler)
})

//This will handle messages sent from the extension to the web server
const messagesHandler = async (event: any) => {
  //TODO:We are sending version in the iframe url, no need to request from extension
  if (event.data.message == 'get-session-details-response') {
    const userVersion = event.data.currentVersion

    //show a warning if the extension is outdated
    if (userVersion != latestExtensionVersion) {
      log('outdated version: ' + userVersion)
      const content =
        'You are using an outdated version of PoshPop. Please update to the latest version on the web store.'
      tabComponentRef.value.displayWarnMessage({ content, critical: true })
      return
    }

    //log("extension up to date: " + userVersion)
  } else if (event.data.message == 'refresh-token') {
    try {
      log('syncing user session ')
      await authStore.refreshToken()
      sendMessage({ message: 'refresh-token-complete' })
    } catch (err) {
      Sentry.captureException(err)
      log('error syncing user session: ', err)
      sendMessage({ message: 'refresh-token-error', error: err })
    }
  }
}
</script>
