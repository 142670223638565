import { serverUrl } from '@/globals/env'
import { useAuthStore } from '@/stores/auth'

export const fetchWrapper = async (path: string, options: any = {}): Promise<Response> => {
  const authStore = useAuthStore()
  let response = await fetch(serverUrl + path, options)

  //refresh token if it has expired
  if (response.status === 419) {
    await authStore.refreshToken()
    options.headers['Authorization'] = 'Bearer ' + authStore.user.token
    response = await fetch(serverUrl + path, options)
  }

  return response
}
